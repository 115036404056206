<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="roles"
      :page.sync="page"
      :items-per-page="rolesPerPage"
      :server-items-length="totalRoles"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>User Roles</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn
                color="green"
                dark
                class="mb-2"
                v-on="on"
                @click="createRole"
                v-if="showCreate"
                >New Role</v-btn
              >
            </template>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Role name"
                          >
                            <v-text-field
                              v-model="editedRole.name"
                              outlined
                              dense
                              label="Name"
                              name="Role name"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Description"
                          >
                            <v-text-field
                              v-model="editedRole.description"
                              outlined
                              dense
                              label="Description"
                              class="mt-n6"
                              name="Description"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row> </v-row>
                      <p>Privileges:</p>
                      <v-row align="center">
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedReadPrivileges"
                            :items="readEntities"
                            item-text="name"
                            item-value="id"
                            chips
                            label="Read Privileges"
                            no-data-text="No Entities Available"
                            multiple
                            outlined
                            deletable-chips
                            return-object
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedCreatePrivileges"
                            :items="createEntities"
                            item-text="name"
                            item-value="id"
                            chips
                            label="Create Privileges"
                            no-data-text="No Entities Available"
                            multiple
                            outlined
                            deletable-chips
                            return-object
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedUpdatePrivileges"
                            :items="updateEntities"
                            item-text="name"
                            item-value="id"
                            chips
                            label="Update Privileges"
                            no-data-text="No Entities Available"
                            multiple
                            outlined
                            deletable-chips
                            return-object
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedDeletePrivileges"
                            :items="deleteEntities"
                            item-text="name"
                            item-value="id"
                            chips
                            label="Delete Privileges"
                            no-data-text="No Entities Available"
                            multiple
                            outlined
                            deletable-chips
                            return-object
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedApprovePrivileges"
                            :items="approveEntities"
                            item-text="name"
                            item-value="id"
                            chips
                            label="Approve Privileges"
                            no-data-text="No Entities Available"
                            multiple
                            outlined
                            deletable-chips
                            return-object
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="success ml-4 mb-4" text type="submit"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editRole(item)" v-if="showEdit">
          mdi-pencil
        </v-icon>
        <v-icon small @click.stop="roleDialog(item)" v-if="showDelete">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this role ?</p>
          <v-btn link small color="blue" @click="deleteRole">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectRolesPerPage"
            v-model="rolesPerPage"
            label="Roles per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-spacer />
        <v-col cols="2"> Total: {{ totalRoles }} </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Role from '@/models/Role'
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    privileges: null,
    storePrivileges: [],
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    roleIndex: null,
    roleItem: null,
    selectedReadPrivileges: [],
    selectedCreatePrivileges: [],
    selectedUpdatePrivileges: [],
    selectedDeletePrivileges: [],
    selectedApprovePrivileges: [],
    readEntities: [],
    createEntities: [],
    updateEntities: [],
    deleteEntities: [],
    approveEntities: [],
    roles: [],
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Descriptions', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedRole: new Role(),
    defaultItem: new Role(),
    selectRolesPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    rolesPerPage: 20,
    totalRoles: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add Role' : 'Edit Role'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'search',
      'currentUser'
    ])
  },
  mounted () {
    this.loadRoles()
    if (this.showCreate || this.showEdit) {
      this.loadDependencies()
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadRoles()
      }
    },
    rolesPerPage: {
      handler () {
        this.page = 1
        this.loadRoles()
      }
    }
  },

  created () {
    this.storePrivileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadRoles () {
      const pageNumber = this.page - 1
      this.$userService
        .fetchAllRoles(pageNumber, this.rolesPerPage)
        .then(response => {
          if (!response.status) {
            this.roles = response.content
            this.totalRoles = response.totalElements
          } else {
            this.$authenticationService.checkAuthentication(
              response.status,
              this.$router,
              this.$store
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.status)
          }
        })
    },
    initializeSelectPrivilegeComponents (privileges) {
      const readPrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'read'
      )
      this.readEntities =
        readPrivileges[0] == null ? [] : readPrivileges[0].entities
      const createPrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'create'
      )
      this.createEntities =
        createPrivileges[0] == null ? [] : createPrivileges[0].entities
      const updatePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'update'
      )
      this.updateEntities =
        updatePrivileges[0] == null ? [] : updatePrivileges[0].entities
      const deletePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'delete'
      )
      this.deleteEntities =
        deletePrivileges[0] == null ? [] : deletePrivileges[0].entities
      const approvePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'approve'
      )
      this.approveEntities =
        approvePrivileges[0] == null ? [] : approvePrivileges[0].entities
    },
    setSelectedPrivileges (privileges) {
      const readPrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'read'
      )
      this.selectedReadPrivileges =
        readPrivileges[0] == null ? [] : readPrivileges[0].entities
      const createPrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'create'
      )
      this.selectedCreatePrivileges =
        createPrivileges[0] == null ? [] : createPrivileges[0].entities
      const updatePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'update'
      )
      this.selectedUpdatePrivileges =
        updatePrivileges[0] == null ? [] : updatePrivileges[0].entities
      const deletePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'delete'
      )
      this.selectedDeletePrivileges =
        deletePrivileges[0] == null ? [] : deletePrivileges[0].entities
      const approvePrivileges = privileges.filter(
        p => p.name.toLowerCase() === 'approve'
      )
      this.selectedApprovePrivileges =
        approvePrivileges[0] == null ? [] : approvePrivileges[0].entities
    },
    loadPrivileges () {
      this.$userService.fetchAllPrivileges().then(response => {
        if (!response.status) {
          this.initializeSelectPrivilegeComponents(response)
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    createRole () {
      this.selectedReadPrivileges = []
      this.selectedCreatePrivileges = []
      this.selectedUpdatePrivileges = []
      this.selectedDeletePrivileges = []
      this.selectedApprovePrivileges = []
    },

    editRole (item) {
      this.editedIndex = this.roles.indexOf(item)
      this.$userService.fetchSingleRole(item.id).then(response => {
        if (response.id) {
          this.editedRole = response
          this.setSelectedPrivileges(response.privileges)
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
      this.dialog = true
    },
    roleDialog (item) {
      this.roleIndex = this.roles.indexOf(item)
      this.roleItem = item
      this.deleteDialog = true
    },
    deleteRole () {
      this.deleteDialog = false
      this.$userService.deleteRole(this.roleItem.id).then(response => {
        if (response.status === 'success') {
          this.roles.splice(this.roleIndex, 1)
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedRole = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1) {
          this.updateRole()
        } else this.newRole()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    collectPrivilegesFromSelectComponents () {
      return [
        ...this.selectedReadPrivileges,
        ...this.selectedCreatePrivileges,
        ...this.selectedUpdatePrivileges,
        ...this.selectedDeletePrivileges,
        ...this.selectedApprovePrivileges
      ]
    },
    updateRole () {
      this.editedRole.privileges = this.collectPrivilegesFromSelectComponents()
      const deletedRoleArray = this.roles.splice(this.editedIndex, 1)
      this.$userService.saveRole(this.editedRole).then(response => {
        if (response.id) {
          this.roles.splice(this.editedIndex, 1, response)
          this.close()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.roles.splice(this.editedIndex, 0, deletedRoleArray[0])
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    newRole () {
      this.editedRole.privileges = this.collectPrivilegesFromSelectComponents()
      this.$userService.saveRole(this.editedRole).then(response => {
        if (response.id) {
          this.roles.unshift(response)
          this.close()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    checkPrivileges () {
      this.storePrivileges.includes('CREATE_ROLES') ||
      this.storePrivileges.includes('ALL_AUTHORITIES')
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.storePrivileges.includes('UPDATE_ROLES') ||
      this.storePrivileges.includes('ALL_AUTHORITIES')
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.storePrivileges.includes('DELETE_ROLES') ||
      this.storePrivileges.includes('ALL_AUTHORITIES')
        ? (this.showDelete = true)
        : (this.showDelete = false)
    },
    loadDependencies () {
      if (
        this.storePrivileges.includes('READ_PRIVILEGES') ||
        this.storePrivileges.includes('ALL_AUTHORITIES')
      ) {
        this.loadPrivileges()
      }
    }
  }
}
</script>
